// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-units-direct-investments-js": () => import("./../../../src/pages/business-units/direct-investments.js" /* webpackChunkName: "component---src-pages-business-units-direct-investments-js" */),
  "component---src-pages-business-units-funds-of-funds-js": () => import("./../../../src/pages/business-units/funds-of-funds.js" /* webpackChunkName: "component---src-pages-business-units-funds-of-funds-js" */),
  "component---src-pages-business-units-private-equity-js": () => import("./../../../src/pages/business-units/private-equity.js" /* webpackChunkName: "component---src-pages-business-units-private-equity-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-csr-bursary-all-levels-js": () => import("./../../../src/pages/csr/bursary-all-levels.js" /* webpackChunkName: "component---src-pages-csr-bursary-all-levels-js" */),
  "component---src-pages-csr-bursary-group-ite-js": () => import("./../../../src/pages/csr/bursary-group-ite.js" /* webpackChunkName: "component---src-pages-csr-bursary-group-ite-js" */),
  "component---src-pages-csr-bursary-group-ntu-js": () => import("./../../../src/pages/csr/bursary-group-ntu.js" /* webpackChunkName: "component---src-pages-csr-bursary-group-ntu-js" */),
  "component---src-pages-csr-bursary-group-nus-js": () => import("./../../../src/pages/csr/bursary-group-nus.js" /* webpackChunkName: "component---src-pages-csr-bursary-group-nus-js" */),
  "component---src-pages-csr-bursary-group-sit-js": () => import("./../../../src/pages/csr/bursary-group-sit.js" /* webpackChunkName: "component---src-pages-csr-bursary-group-sit-js" */),
  "component---src-pages-csr-index-js": () => import("./../../../src/pages/csr/index.js" /* webpackChunkName: "component---src-pages-csr-index-js" */),
  "component---src-pages-csr-letter-to-bursary-recipients-js": () => import("./../../../src/pages/csr/letter-to-bursary-recipients.js" /* webpackChunkName: "component---src-pages-csr-letter-to-bursary-recipients-js" */),
  "component---src-pages-ich-philosophy-js": () => import("./../../../src/pages/ich-philosophy.js" /* webpackChunkName: "component---src-pages-ich-philosophy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-media-qing-hai-project-js": () => import("./../../../src/pages/media/qing-hai-project.js" /* webpackChunkName: "component---src-pages-media-qing-hai-project-js" */),
  "component---src-pages-newsletters-js": () => import("./../../../src/pages/newsletters.js" /* webpackChunkName: "component---src-pages-newsletters-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */)
}

